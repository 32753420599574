const BannerData = {
	AgencyText: [
		{
			id: 1,
			btitle: 'Starategic design and technology agency',
			description: 'Why I say old chap that is spiffing off his nut arse pear shaped plastered Jeffrey bodge barney some dodgy.',
		}
	],
	ClientsLogo: [
		{
			id: 1,
			image: 'logo_01.png'
		},
		{
			id: 2,
			image: 'logo_02.png'
		},
		{
			id: 3,
			image: 'logo_03.png',
		},
		{
			id: 4,
			image: 'logo_04.png',
		},
		{
			id: 5,
			image: 'logo_05.png'
		},
		{
			id: 6,
			image: 'logo_06.png'
		},
		{
			id: 7,
			image: 'logo_07.png'
		},
		{
			id: 8,
			image: 'logo_08.png'
		}
	],
	AgencyTestimonial: [
		{
			id: 1,
			image: 'author_img.png',
			authorName: 'Lurch Schpellchek',
			authorPost: 'Maker, Hardware Engineer',
			description: 'The best MakerCommunity I have ever seen!',
		},
		{
			id: 2,
			image: 'author_img.png',
			authorName: 'Lurch Schpellchek',
			authorPost: 'FabLab Instructor',
			description: 'The best MakerCommunity I have ever seen!',
		}
	]
}
export default BannerData;