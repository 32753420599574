import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import TermsContent from '../components/Blog/TermsContent'
import FooterTwo from '../components/Footer/FooterTwo';
import FooterSecurity from "../components/Footer/FooterSecurity";
import FooterData from '../components/Footer/FooterData';

const Terms = ({
	title,
	description,
	content,
}) => {
	return (
		<div className="body_wrapper">
			<CustomNavbar
				slogo="sticky_logo"
				mClass="menu_four"
				nClass="w_menu ml-auto mr-auto"
			/>

			<Breadcrumb
				breadcrumbClass="breadcrumb_area"
				imgName="breadcrumb/banner_bg.png"
				Ptitle={title}
				Pdescription={description}
			/>
			
			<TermsContent 
				content={content}
			/>

			<FooterSecurity FooterData={FooterData} />
		</div>
	)
}
export default Terms;