import React from 'react';

const SupportBanner = () => {
	return (
		<section className="support_home_area">
			<div className="banner_top">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center">
							<h2 className="f_p f_size_40 l_height60 wow fadeInUp" data-wow-delay="0.3s">Learn, Share, Collaborate <br />A <span className="f_700">Community</span> for Makers</h2>
							<p className="f_size_18 l_height30 wow fadeInUp" data-wow-delay="0.5s">MakerGram is an Open Community <br />for Makers and hardware enthusiasts to grow further!</p>

							<a href="https://makergram.com/community" target="_blank" className="er_btn er_btn_two">Get Started</a>
						</div>
					</div>
					<div className="support_home_img wow fadeInUp" data-wow-delay="0.9s">
						<img src={require("../../img/new-home/banner.png")} alt="" />
					</div>
				</div>
			</div>
		</section>
	)
}
export default SupportBanner;