import React from 'react';
import Blogrightsidebar from './Blogrightsidebar';
import ServiceData from '../Service/ServiceData';
const Jointelegram = () => {
	return (
		<section className="blog_area sec_pad">
			<div className="container">
				<div className="row">
					<div className="col-lg-8 blog_sidebar_left">
						<div className="blog_single mb_50">
							
							<div className="blog_content">
									
								<blockquote className="blockquote mb_40">
									<h6 className="mb-0 f_size_18 l_height30 f_p f_400">Join by seraching @makergram in your Telegram App or goto https://t.me/makergram in your phone</h6>
								</blockquote>
								
							</div>
						</div>
						
						
					</div>
				</div>
			</div>
		</section>
	)
}
export default Jointelegram;