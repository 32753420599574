import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Sticky from 'react-stickynode';

class CustomNavbar extends Component {
	render() {
		var { mClass, nClass, cClass, slogo, hbtnClass } = this.props;
		return (
			<Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
				<header className="header_area">
					<nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
						<div className={`container ${cClass}`}>
							<Link className={`navbar-brand ${slogo}`} to="/">
								<img src={require("../img/logo2.png")} alt="" />
								<img src={require("../img/logo.png")} alt="logo" />
							</Link>
							<button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
								<span className="menu_toggle">
									<span className="hamburger">
										<span></span>
										<span></span>
										<span></span>
									</span>
									<span className="hamburger-cross">
										<span></span>
										<span></span>
									</span>
								</span>
							</button>

							<div className="collapse navbar-collapse" id="navbarSupportedContent">
								<ul className={`navbar-nav menu ml-auto ${nClass}`}>
									<li className="nav-item dropdown submenu mega_menu mega_menu_two">
										<a href="/" title="Pricing" className="nav-link">Home</a>
									</li>

									<li className="dropdown submenu nav-item">
										<a href="https://makergram.com/community/" target="_blank" className="dropdown-toggle nav-link">Community</a>
									</li>
									<li className="dropdown submenu nav-item">
										<a href="https://makergram.com/blog/" target="_blank" title="Pages" className="dropdown-toggle nav-link">Blog</a>
									</li>
									<li className="dropdown submenu nav-item">
										<a href="https://go.makergram.com/mg-makerpack" target="_blank" title="Pages" className="dropdown-toggle nav-link">MakerPack</a>
									</li>
									<li className="nav-item dropdown submenu">
										<a href="https://go.makergram.com/mg-makerkits" target="_blank" className="nav-link dropdown-toggle">MakerKit</a>
									</li>
								</ul>
								<a className={`btn_get btn_hover ${hbtnClass}`} href="https://makergram.com/community">Get Started</a>
							</div>
						</div>
					</nav>
				</header>
			</Sticky>
		);
	}
}

export default CustomNavbar;