import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import Jointelegram from '../components/Blog/Jointelegram'
import FooterSecurity from "../components/Footer/FooterSecurity";
import FooterData from '../components/Footer/FooterData';

const JoinTelegram = () => {
	return (
		<div className="body_wrapper">
			<CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto" />
			<Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Join Our Telegram" Pdescription="" />
			<Jointelegram />
			<FooterSecurity FooterData={FooterData} />
		</div>
	)
}
export default JoinTelegram;