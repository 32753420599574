import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sectitle from './Title/Sectitle';

class SupportIntegration extends Component {
	constructor() {
		super();
		this.state = {
			integrationItem: [
				{
					"id": 100,
					"iImage": "briteverify.png",
					"text": "Trainings",
					"isExternal": true,
					"url": "https://go.makergram.com/request-training"
				},
				{
					"id": 101,
					"iImage": "campfire.png",
					"text": "Resources",
					"isExternal": true,
					"url": "https://go.makergram.com/get-free-resources",
				},
				{
					"id": 102,
					"iImage": "nicereply-1.png",
					"text": "Top Contributors",
					"isExternal": true,
					"url": "https://go.makergram.com/mgc-top-contributors"
				},
				{
					"id": 103,
					"iImage": "webhooks.png",
					"text": "Join Our Telegram",
					"isExternal": false,
					"url": '/join-telegram',
				},
				{
					"id": 104,
					"iImage": "metorik.png",
					"text": "MakerPack",
					"isExternal": true,
					"url": "https://go.makergram.com/mg-makerpack"
				},
				{
					"id": 105,
					"iImage": "kissmetrics.png",
					"text": "MakerKits",
					"isExternal": true,
					"url": "https://go.makergram.com/mg-makerkits"
				}
			]
		};
	}

	render() {
		return (
			<section className="support_integration_area">
				<div className="container">
					<Sectitle sClass="sec_title text-center mb_70" Title='Explore Opportunities' TitleP='Explore more free tools and resources to advance you capabilities.!' />
					<div className="row flex-row-reverse">
						<div className="col-lg-9 col-md-10 col-sm-12">
							<div className="row">
								{this.state.integrationItem.map(post => (
									<div className="col-lg-4 col-md-4 col-sm-6" key={post.id}>
										{
											(post.isExternal)? (
												<a href={post.url} target="_blank" className="s_integration_item">
													<img src={require("../img/new-home/" + post.iImage)} alt="" />
													<h5>{post.text}</h5>
												</a>
											): (
												<Link to={post.url} className="s_integration_item">
													<img src={require("../img/new-home/" + post.iImage)} alt="" />
													<h5>{post.text}</h5>
												</Link>
											)
										}

										{/* <a href={post.url} target="_blank" className="s_integration_item">
											<img src={require("../img/new-home/" + post.iImage)} alt="" />
											<h5>{post.text}</h5>
										</a> */}
									</div>
								))}
							</div>
						</div>
						<div className="col-lg-3 col-md-2 col-sm-12">
							<img className="integration_img" src={require("../img/new-home/tree.png")} alt="" />
						</div>
					</div>
				</div>
			</section>
		)
	}
}
export default SupportIntegration;
