import React from 'react';

const SupportItem = () => {
	return (
		<section className="support_help_area sec_pad">
			<div className="container">
				<h2 className="f_p f_size_30 l_height50 f_600 t_color text-center wow fadeInUp mb_60">What would you like to do today? something great?</h2>
				<div className="d-flex">
					<div className="support_item">
						<img src={require('../../img/new-home/ticket-support.png')} alt="" />
						<h4>Best makers across the world</h4>
						<a href="https://makergram.com/community/register" target="_blank" className="software_banner_btn btn_submit f_size_15 f_500">Join Community</a>
					</div>
					<div className="support_item">
						<img src={require('../../img/new-home/email.png')} alt="" />
						<h4>Innovation and Beyond</h4>
						<a href="https://makergram.com/blog/" target="_blank" className="software_banner_btn btn_submit f_size_15 f_500">Explore Projects</a>
					</div>
				</div>
			</div>
		</section>
	)
}

export default SupportItem;

