import React from 'react';

const SupportSubscribe = () => {
	return (
		<section className="support_subscribe_area sec_pad">
			<img className="h_leaf one wow fadeInUp" data-wow-delay="0.4s" src={require("../img/new-home/tree-left.png")} alt="" />
			<img className="h_leaf two wow fadeInUp" data-wow-delay="0.6s" src={require("../img/new-home/tree-right.png")} alt="" />
			<div className="container">
				<div className="sec_title text-center mb_50 wow fadeInUp" data-wow-delay="0.3s">
					<h2 className="f_p f_size_30 l_height50 f_600 t_color3">Subscribe to MakerGram Live 📡</h2>
					<p className="f_400 f_size_16 mb-0">A newsletter where we pull together information about Interesting Projects, Industry News,  Upcoming Events, Job Opportunities and other interesting bits that you may find useful. 😉</p>
					<a href="https://go.makergram.com/signup-newsletter-web" target="_blank" className="er_btn er_btn_two">SignUp Today!</a>
				</div>
			</div>
		</section>
	)
}

export default SupportSubscribe;