import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import './App.css';

class ScrollToTopRoute extends Component {
	componentDidUpdate(prevProps) {
		if (this.props.path === this.props.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
			window.scrollTo(0, 0)
		}
	}

	render() {
		const {
			component: Component,
			componentProps,
			...rest
		} = this.props;
		console.log("ScrollToTopRoute -> render -> componentProps", componentProps)

		return (
			<Route
				{...rest}
				render={(props) => {
					return (<Component {...props} {...componentProps}/>)
				}}
			/>
		);
	}
}

export default withRouter(ScrollToTopRoute);